import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { StoreInterface, WABotSession } from "../../assets/interfaces";
import CartContext from "../../contexts/cart-context";
import usePreventFirstRun from "../hooks/usePreventFirstRun";
import Portal from "../portal";
import CheckoutModal from "./modals/checkout";
import SelectVariantModal from "./modals/select-variant";
import { useAppendScript } from "../hooks/useAppendScript";
import CustomerCartInfo from "./modals/customer-cart-info";

interface Props {
  storeIsOpen?: boolean;
  phoneNumber: string;
  store: StoreInterface;
  productPage?: boolean;
  botSession?: WABotSession;
  openCart?: boolean;
}

const StoreFrontCart: React.FC<Props> = ({ openCart, botSession, store, productPage }) => {
  const {
    cart,
    toggleModal,
    modals,
    currentProduct,
    addOrRemoveItem,
    cartIsFromServer,
    setCartIsFromServer,
    formatAsCurrency,
    downloadCart,
  } = CartContext.useContainer();
  const cartLengthRef = useRef<number>(0);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (openCart) toggleModal("cart");
  }, [openCart]);

  useEffect(() => {
    if (botSession?.cart_id) downloadCart(botSession.cart_id);
  }, [botSession]);

  useAppendScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places&callback=initMap&solution_channel=GMP_QB_addressselection_v1_cA`,
    "GOOGLE_PLACE_SCRIPT"
  );

  useEffect(() => {
    (window as any).initMap = (e) => {
      console.log(e, "Google places loaded");
    };
  }, []);

  useEffect(() => {
    if (cartIsFromServer) {
      cartLengthRef.current = cart.length;
      return;
    }

    if (cart.length > cartLengthRef.current) {
      setShowBanner(true);

      setTimeout(() => {
        setShowBanner(false);
      }, 2500);
    }

    cartLengthRef.current = cart.length;
  }, [cart, cartIsFromServer]);

  return (
    <>
      {!productPage && (
        <button
          className="fixed right-6.25 sm:right-12 md:right-16 lg:right-20 xl:right-25 bottom-12 no-outline bg-primary-500 h-15 w-15 rounded-full flex items-center justify-center shadow-primary z-[900]"
          onClick={() => toggleModal("cart")}
        >
          {/* prettier-ignore */}
          <svg viewBox="0 0 24 24" fill="none" className="h-6">
            <path d="M19.24 5.58006H18.84L15.46 2.20006C15.19 1.93006 14.75 1.93006 14.47 2.20006C14.2 2.47006 14.2 2.91006 14.47 3.19006L16.86 5.58006H7.14L9.53 3.19006C9.8 2.92006 9.8 2.48006 9.53 2.20006C9.26 1.93006 8.82 1.93006 8.54 2.20006L5.17 5.58006H4.77C3.87 5.58006 2 5.58006 2 8.14006C2 9.11006 2.2 9.75006 2.62 10.1701C2.86 10.4201 3.15 10.5501 3.46 10.6201C3.75 10.6901 4.06 10.7001 4.36 10.7001H19.64C19.95 10.7001 20.24 10.6801 20.52 10.6201C21.36 10.4201 22 9.82006 22 8.14006C22 5.58006 20.13 5.58006 19.24 5.58006Z" fill="white"/>
            <path d="M19.05 12H4.86997C4.24997 12 3.77997 12.55 3.87997 13.16L4.71997 18.3C4.99997 20.02 5.74997 22 9.07997 22H14.69C18.06 22 18.66 20.31 19.02 18.42L20.03 13.19C20.15 12.57 19.68 12 19.05 12ZM10.61 18.45C10.61 18.84 10.3 19.15 9.91997 19.15C9.52997 19.15 9.21997 18.84 9.21997 18.45V15.15C9.21997 14.77 9.52997 14.45 9.91997 14.45C10.3 14.45 10.61 14.77 10.61 15.15V18.45ZM14.89 18.45C14.89 18.84 14.58 19.15 14.19 19.15C13.81 19.15 13.49 18.84 13.49 18.45V15.15C13.49 14.77 13.81 14.45 14.19 14.45C14.58 14.45 14.89 14.77 14.89 15.15V18.45Z" fill="white"/>
          </svg>
          {cart.length > 0 && (
            <div className="absolute top-0 right-0 h-5 w-5 rounded-full bg-cart-badge text-white text-xs font-bold flex items-center justify-center">
              <span className="inline-block">{cart.length}</span>
            </div>
          )}
        </button>
      )}
      <div
        className={classNames(
          "fixed top-0 left-0 w-full bg-success flex items-center text-white font-medium text-1xs sm:text-sm justify-center py-2.5 px-3 transition-all ease-out duration-200 transform z-[9999]",
          { "-translate-y-full opacity-0": !showBanner, "translate-y-0 opacity-100": showBanner }
        )}
      >
        Item added to cart, open cart to set quantity
      </div>
      <Portal>
        <CheckoutModal show={modals.cart} toggle={() => toggleModal("cart")} store={store} />
        {currentProduct && (
          <SelectVariantModal
            cart={cart}
            show={modals.variants}
            toggle={() => toggleModal("variants")}
            product={currentProduct}
            toggleCartItem={addOrRemoveItem}
            formatAsCurrency={formatAsCurrency}
          />
        )}
        <CustomerCartInfo show={modals.customer_modal} toggle={() => toggleModal("customer_modal")} />
      </Portal>
    </>
  );
};

export default StoreFrontCart;
